<template>

	<div class="home">

		<h1>{{$application.name}}</h1>

	</div>

</template>

<script>
export default {
	name: 'DefaultHome'
}
</script>

<style lang="scss" scoped>
</style>
