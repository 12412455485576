<template>

	<section class="survey-contact">

		<section class="user">

			<div class="form-row">

				<div class="form-group col-md-6">
					<label for="firstName">{{fieldNames[language].firstName}}*</label>
					<input id="firstName" type="text" autocomplete="given-name" maxlength="20" class="form-control" required v-model="contact.firstName" v-on:input="validation.contact.firstName.$touch" v-bind:class="{'is-invalid': validation.contact.firstName.$invalid && validation.contact.firstName.$dirty, 'is-valid': !validation.contact.firstName.$invalid && validation.contact.firstName.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.firstName.$invalid && validation.contact.firstName.$dirty || isSubmitted">
							{{formFieldErrors[language].firstName}}
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="lastName">{{fieldNames[language].lastName}}*</label>
					<input id="lastName" type="text" autocomplete="family-name" maxlength="20" class="form-control" required v-model="contact.lastName" v-on:input="validation.contact.lastName.$touch" v-bind:class="{'is-invalid': validation.contact.lastName.$invalid && validation.contact.lastName.$dirty, 'is-valid': !validation.contact.lastName.$invalid && validation.contact.lastName.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.firstName.$invalid && validation.contact.firstName.$dirty || isSubmitted">
							{{formFieldErrors[language].lastName}}
						</div>
					</div>
				</div>

			</div>

			<div class="form-row"  v-if="formSize !== 3">
				<div class="form-group col-md-6">
					<label for="phoneNumber">{{fieldNames[language].phoneNumber}}*</label>
					<input inputmode="tel" id="phoneNumber" type="tel" autocomplete="tel" maxlength="13" class="form-control" required v-model="contact.phoneNumber" v-on:input="validation.contact.phoneNumber.$touch" v-bind:class="{'is-invalid': validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty, 'is-valid': !validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty || isSubmitted">
							{{formFieldErrors[language].phoneNumber}}
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="emailAddress">{{fieldNames[language].emailAddress}}*</label>
					<input inputmode="email" id="emailAddress" type="email" autocomplete="email" maxlength="50" class="form-control" required v-model="contact.emailAddress" v-on:input="validation.contact.emailAddress.$touch" v-bind:class="{'is-invalid': validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty, 'is-valid': !validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty || isSubmitted">
							{{formFieldErrors[language].emailAddress}}
						</div>
					</div>
				</div>

			</div>

			<div class="form-row"  v-if="formSize == 3">
				
				<div class="form-group col-md-12">
					<label for="emailAddress">{{fieldNames[language].emailAddress}}*</label>
					<input inputmode="email" id="emailAddress" type="email" autocomplete="email" maxlength="50" class="form-control" required v-model="contact.emailAddress" v-on:input="validation.contact.emailAddress.$touch" v-bind:class="{'is-invalid': validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty, 'is-valid': !validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty || isSubmitted">
							{{formFieldErrors[language].emailAddress}}
						</div>
					</div>
				</div>

			</div>

			<p class="small" v-html="disclaimers[language].email"></p>

			<div class="form-group" v-if="formSize == 1">
				<label for="streetAddress">{{fieldNames[language].streetAddress}}*</label>
				<input id="streetAddress" type="text" autocomplete="street-address" maxlength="35" class="form-control" required v-model="contact.streetAddress" v-on:input="validation.contact.streetAddress.$touch" v-bind:class="{'is-invalid': validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty, 'is-valid': !validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty || isSubmitted">
						{{formFieldErrors[language].streetAddress}}
					</div>
				</div>
			</div>

			<div class="form-row" v-if="formSize == 1">

				<div class="form-group col-md-6">
					<label for="city">{{fieldNames[language].city}}*</label>
					<input id="city" type="text" autocomplete="address-level2" maxlength="30" class="form-control" required v-model="contact.city" v-on:input="validation.contact.city.$touch" v-bind:class="{'is-invalid': validation.contact.city.$invalid && validation.contact.city.$dirty, 'is-valid': !validation.contact.city.$invalid && validation.contact.city.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.city.$invalid && validation.contact.city.$dirty || isSubmitted">
							{{formFieldErrors[language].city}}
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="state">{{fieldNames[language].state}}*</label>
					<input id="state" type="text" autocomplete="address-level1" maxlength="50" class="form-control" required v-model="contact.state" v-if="contact.country != 'US'" v-on:input="validation.contact.state.$touch" v-bind:class="{'is-invalid': validation.contact.state.$invalid && validation.contact.state.$dirty, 'is-valid': !validation.contact.state.$invalid && validation.contact.state.$dirty}">
					<select id="state" autocomplete="address-level1" class="custom-select" required v-model="contact.state" v-if="contact.country == 'US'">
						<option v-for="(usstate) in usStates" v-bind:key="usstate.code" v-bind:value="usstate.code">{{usstate.name}}</option>
					</select>
					<div class="invalid-feedback">
						<div v-if="validation.contact.state.$invalid && validation.contact.state.$dirty || isSubmitted">
							{{formFieldErrors[language].state}}
						</div>
					</div>
				</div>

			</div>

			<div class="form-row" v-if="formSize == 1">
				<div class="form-group col-md-6">
					<label for="postalCode">{{fieldNames[language].postalCode}}*</label>
					<input inputmode="numeric" id="postalCode" type="text" autocomplete="postal-code" class="form-control" required  v-model="contact.postalCode" v-on:input="validation.contact.postalCode.$touch" v-bind:class="{'is-invalid': validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty, 'is-valid': !validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty || isSubmitted">
							{{formFieldErrors[language].postalCode}}
						</div>
					</div>
				</div>
				<div class="form-group col-md-6">
					<label for="country">{{fieldNames[language].country}}*</label>
					<select id="country" autocomplete="country" class="custom-select" required v-model="contact.country" >
						<option v-for="(country) in countries" v-bind:key="country.code" v-bind:value="country.code">{{country.name}}</option>
					</select>
					<div class="invalid-feedback">
						{{formFieldErrors[language].country}}
					</div>
				</div>
			</div>

		</section>

		<section class="note" v-if="hasNoteField">

			<div class="form-row">

				<div class="form-group col-md-12">
					<label for="note">{{noteLabel}}</label>
					<textarea id="note" class="form-control" v-model="note" v-on:input="updateNote"></textarea>
				</div>

			</div>

		</section>

		<p class="small" v-html="disclaimers[language].offer" v-if="formSize == 1"></p>

		<div class="input-option mb-3" v-if="hasSMSField" v-bind:class="{selected: contact.sms}" v-on:click="contact.sms = !contact.sms">

			<div class="input-button">

				<div class="option-checkbox">
					<div v-show="contact.sms"><i class="fas fa-check"></i></div>
				</div>

			</div>

			<div class="input-label">

				<p class="small" v-html="disclaimers[language].sms"></p>

			</div>

		</div>

	</section>

</template>

<script>
import fieldNames from '@/assets/data/fieldnames.json';
import formFieldErrors from '@/assets/data/formfielderrors.json';
import disclaimers from '@/assets/data/disclaimers.json';
import countries from '@/assets/data/countries.json';
import usStates from '@/assets/data/countries/united-states.json';

export default {
	name: 'SurveyContact',
	props: {
		defaultCountry: {
			type: String
		},
		language: {
			type: String,
			default: 'en'
		},
		contact: {
			type: Object,
			required: true
		},
		formSize: {
			type: Number,
			required: true
		},
		hasNoteField: {
			type: Boolean,
			default: false
		},
		noteLabel: {
			type: String,
			default: 'Note'
		},
		validation: {
			type: Object
		},
		isSubmitted: {
			type: Boolean,
			default: false
		},
		hasSMSField: {
			type: Boolean,
			default: false
		}
		
	},
	data () {
		return {
			fieldNames: fieldNames,
			formFieldErrors: formFieldErrors,
			disclaimers: disclaimers,
			countries: countries,
			usStates: usStates,
			note: '',
		}
	},
	
	created () {

		this.contact.country = this.defaultCountry;
		
		if (this.hasSMSField) {

			this.contact.sms = true;
		}
		
	},
	methods: {
		
		updateNote () {

			this.$emit('update:note', this.note);
		}

	}
}
</script>

<style lang="scss" scoped>
</style>
