<template>

	<section class="donation-tribute">

		<div class="form-row">

			<div class="form-group col-md-12">
				<label for="tributeType">Tribute Type*</label>
				<select id="tributeType" v-model="tribute.tributeType" class="form-control" required v-on:input="validation.tribute.tributeType.$touch" v-bind:class="{'is-invalid': validation.tribute.tributeType.$invalid && validation.tribute.tributeType.$dirty, 'is-valid': !validation.tribute.tributeType.$invalid && validation.tribute.tributeType.$dirty}">
					<option selected disabled></option>
					<option value="Memory">In Memory Of ...</option>
					<option value="Honor">In Honor Of ...</option>
				</select>
				<div class="invalid-feedback">
					<div v-if="validation.tribute.tributeType.$invalid && validation.tribute.tributeType.$dirty || isSubmitted">
						Please select a tribute type.
					</div>
				</div>
			</div>

			<div class="form-group col-md-6">
				<label for="senderName">Sender Name*</label>
				<input id="senderName" v-model="tribute.senderName" type="text" class="form-control" required v-on:input="validation.tribute.senderName.$touch" v-bind:class="{'is-invalid': validation.tribute.senderName.$invalid && validation.tribute.senderName.$dirty, 'is-valid': !validation.tribute.senderName.$invalid && validation.tribute.senderName.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.tribute.senderName.$invalid && validation.tribute.senderName.$dirty || isSubmitted">
						Please provide an sender name.
					</div>
				</div>
			</div>

			<div class="form-group col-md-6">
				<label for="honoreeName">Honoree Name*</label>
				<input id="honoreeName" v-model="tribute.honoreeName" type="text" class="form-control" required  v-on:input="validation.tribute.honoreeName.$touch" v-bind:class="{'is-invalid': validation.tribute.honoreeName.$invalid && validation.tribute.honoreeName.$dirty, 'is-valid': !validation.tribute.honoreeName.$invalid && validation.tribute.honoreeName.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.tribute.honoreeName.$invalid && validation.tribute.honoreeName.$dirty || isSubmitted">
						Please provide an honoree name.
					</div>
				</div>
			</div>

		</div>

		<div class="input-option mb-3" v-bind:class="{selected: tribute.isCustomAddress}" v-on:click="tribute.isCustomAddress = !tribute.isCustomAddress">

			<div class="input-button">

				<div class="option-checkbox">
					<div v-show="tribute.isCustomAddress" ><i class="fas fa-check"></i></div>
				</div>

			</div>

			<div class="input-label">

				<strong>Use a Different Mailing Address</strong><br />
				<small>Select this option if you would like to use an address that is different from your billing address.</small>

			</div>

		</div>

		<div v-show="tribute.isCustomAddress">

			<div class="form-row">

				<div class="form-group col-md-6">
					<label for="firstName">{{fieldNames[language].firstName}}*</label> 
					<input id="firstName" required v-model="tribute.recipient.firstName" type="text" class="form-control"  maxlength="50"  v-on:input="validation.tribute.recipient.firstName.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.firstName.$invalid && validation.tribute.recipient.firstName.$dirty, 'is-valid': !validation.tribute.recipient.firstName.$invalid && validation.tribute.recipient.firstName.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.firstName.$invalid && validation.tribute.recipient.firstName.$dirty || isSubmitted">
							Please enter your first name.
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="lastName">{{fieldNames[language].lastName}}*</label>
					<input id="lastName" required v-model="tribute.recipient.lastName" type="text" class="form-control" maxlength="50"  v-on:input="validation.tribute.recipient.lastName.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.lastName.$invalid && validation.tribute.recipient.lastName.$dirty, 'is-valid': !validation.tribute.recipient.lastName.$invalid && validation.tribute.recipient.lastName.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.lastName.$invalid && validation.tribute.recipient.lastName.$dirty || isSubmitted">
							Please enter your last name.
						</div>
					</div>
				</div>

			</div>

			<div class="form-group">
				<label for="streetAddress">{{fieldNames[language].streetAddress}}*</label>
				<input id="streetAddress" v-model="tribute.recipient.streetAddress" type="text" class="form-control" maxlength="50" required v-on:input="validation.tribute.recipient.streetAddress.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.streetAddress.$invalid && validation.tribute.recipient.streetAddress.$dirty, 'is-valid': !validation.tribute.recipient.streetAddress.$invalid && validation.tribute.recipient.streetAddress.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.tribute.recipient.streetAddress.$invalid && validation.tribute.recipient.streetAddress.$dirty || isSubmitted">
						Please enter a valid street address.
					</div>
				</div>
			</div>

			<div class="form-row">

				<div class="form-group col-md-6">
					<label for="city">{{fieldNames[language].city}}*</label>
					<input id="city" v-model="tribute.recipient.city" type="text" class="form-control" maxlength="50" required  v-on:input="validation.tribute.recipient.city.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.city.$invalid && validation.tribute.recipient.city.$dirty, 'is-valid': !validation.tribute.recipient.city.$invalid && validation.tribute.recipient.city.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.city.$invalid && validation.tribute.recipient.city.$dirty || isSubmitted">
							Please provide a valid city.
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="state">{{fieldNames[language].state}}*</label>
					<input id="state" v-model="tribute.recipient.state" type="text" class="form-control" maxlength="50" required v-on:input="validation.tribute.recipient.state.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.state.$invalid && validation.tribute.recipient.state.$dirty, 'is-valid': !validation.tribute.recipient.state.$invalid && validation.tribute.recipient.state.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.state.$invalid && validation.tribute.recipient.state.$dirty || isSubmitted">
							Please provide a valid state.
						</div>
					</div>
				</div>

			</div>

			<div class="form-row">

				<div class="form-group col-md-6">
					<label for="postalCode">{{fieldNames[language].postalCode}}*</label>
					<input inputmode="numeric" id="postalCode" v-model="tribute.recipient.postalCode" type="text" maxlength="16" required class="form-control"  v-on:input="validation.tribute.recipient.postalCode.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.postalCode.$invalid && validation.tribute.recipient.postalCode.$dirty, 'is-valid': !validation.tribute.recipient.postalCode.$invalid && validation.tribute.recipient.postalCode.$dirty}">
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.postalCode.$invalid && validation.tribute.recipient.postalCode.$dirty || isSubmitted">
							Please provide a valid zip/postal code.
						</div>
					</div>
				</div>

				<div class="form-group col-md-6">
					<label for="country">{{fieldNames[language].country}}*</label>  
					<select id="country" v-model="tribute.recipient.country" class="form-control" required v-on:input="validation.tribute.recipient.country.$touch" v-bind:class="{'is-invalid': validation.tribute.recipient.country.$invalid && validation.tribute.recipient.country.$dirty, 'is-valid': !validation.tribute.recipient.country.$invalid && validation.tribute.recipient.country.$dirty}">
					<option v-for="(country) in countries" v-bind:key="country.code" v-bind:value="country.code">{{country.name}}</option>
					</select>
					<div class="invalid-feedback">
						<div v-if="validation.tribute.recipient.country.$invalid && validation.tribute.recipient.country.$dirty || isSubmitted">
							Please select a valid country.
						</div>
					</div>
				</div>

			</div>

		</div>

	</section>

</template>

<script>
import fieldNames from '@/assets/data/fieldnames.json';
import countries from '@/assets/data/countries.json';

export default {
	name: 'DonationTribute',
	props: {
		tribute: {
			type: Object,
			required: true
		},
		language: {
			type: String,
			default: 'en'
		},
		validation: {
			type: Object
		},
		isSubmitted: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			fieldNames: fieldNames,
			countries: countries
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
