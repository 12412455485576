<template>

	<div id="app">

		<main>

			<div class="container">

				<div class="row justify-content-center">

					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">

						<router-view></router-view>

					</div>

				</div>

			</div>

		</main>

		<GlobalFooter />

	</div>

</template>

<script>
import GlobalFooter from '@/views/partials/Footer.vue';

export default {
	name: 'App',
	components: {
		GlobalFooter
	}
}
</script>

<style lang="scss">
main {
	padding: 2rem 0;
}
</style>


