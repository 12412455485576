<template>

	<div class="media">

		<div class="image-container" v-if="mediaType == 1">

			<img class="img-fluid" v-bind:src="media" />

		</div>

		<div class="video-container" v-if="mediaType == 2">
			<div class="video-player">

				<iframe allowfullscreen v-bind:src="'https://player.vimeo.com/video/'+ media +'?autoplay=1'" frameborder="0" allow="autoplay"></iframe>
			
			</div>
		</div>

		<div class="brightcove-container" v-if="mediaType == 3">

			<div class="brightcove-player">

				<iframe allowfullscreen v-bind:src="'https://players.brightcove.net/1366266431001/CGI9WeFS3_default/index.html?videoId=' + media"></iframe>

			</div>

		</div>

	</div>

</template>

<script>
export default {
	name: 'FormsMedia',
	props: ['mediaType', 'media']
}
</script>

<style lang="scss" scoped>
.image-container {

	width: 100%;
	text-align: center;
}

.brightcove-container, .video-container {

	position: relative;
	display: block;
	width: 100%;

	.brightcove-player, .video-player {

		padding-top: 56.25%;

		iframe {

			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #000;
		}
	}
}
</style>
