<template>
	<div class="acknowledgment">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[acknowledgment.language].url">
					<img v-bind:src="logos[acknowledgment.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid" />
				</a><br /><br />

				<h1 class="title mb-4" v-html="acknowledgment.title"></h1>

			</div>

		</div>

		<div class="row">

			<div class="col-12">

				<div class="content">
					<p>Thank you for your feedback! We&rsquo;ve received your survey response and will carefully read and consider your comments.</p>
					<p>If you have any questions or if we can help you in any way, please call our friendly Donor Relations staff at <strong>1-800-486-8844</strong> or email us at <a href="mailto:info@ifcj.org">info@ifcj.org</a>.</p>
					<p>Thank you again for everything you are doing for the Jewish people.</p>
					<p>With prayers for <em>shalom</em>, peace,</p> 
					<p>Your Fellowship Family</p>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import logos from '@/assets/data/logos.json';

export default {
	name: 'SurveyDonorSatisfactionAcknowledgment',
	data () {
		return {
			logos: logos,
			acknowledgment: {
				language: 'en',
				pageTitle: '',
				title: 'Thanks for Building a Better Fellowship',
			}
		}
	},
	created () {

		document.title;

	}
}
</script>

<style lang="scss" scoped>
</style>
