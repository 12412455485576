<template>

	<div class="donation-main">

		<section class="introduction">

			<div class="row">

				<div class="col-12 text-center">

					<a v-bind:href="logos[language].url">
						<img v-bind:src="logos[language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo img-fluid" />
					</a>

					<h1>Donate Now</h1>

					<p>The Fellowship depends on support from friends like you to help Jews in need around the world &ndash; innocent children, impoverished families, Holocaust survivors, and so many more. Please give generously today so that this critically important work can continue!</p>

				</div>

			</div>

		</section>

		<form class="donation" novalidate v-on:submit.prevent="postDonation" v-bind:class="{'was-validated': isSubmitted}">

			<section class="frequency">

				<h3><span class="section-index">1</span>Select Your Frequency</h3>

				<hr />

				<div class="input-group" v-bind:class="{'is-invalid': payment.frequency == null && isSubmitted}">

					<div class="input-option" v-bind:class="{selected: payment.frequency == 2}" v-on:click="setFrequency(2)">

						<div class="input-button">

							<div class="option-radio">
								<div v-show="payment.frequency == 2"><i class="fas fa-circle"></i></div>
							</div>

						</div>

						<div class="input-label">

							<strong>Give a Monthly Gift</strong>

						</div>

					</div>

					<div class="input-option" v-bind:class="{selected: payment.frequency == 1}" v-on:click="setFrequency(1)">

						<div class="input-button">

							<div class="option-radio">
								<div v-show="payment.frequency == 1"><i class="fas fa-circle"></i></div>
							</div>

						</div>

						<div class="input-label">

							<strong>Give a Single Gift</strong>

						</div>

					</div>

				</div>

				<div class="text-danger text-center small mt-3" v-show="payment.frequency == null && isSubmitted">
					You must select a payment frequency.
				</div>

			</section>

			<section class="programs">

				<h3><span class="section-index">2</span>Select Your Program</h3>

				<hr />

				<div class="input-group">

					<div class="input-card" v-for="(program, index) in programs" v-bind:key="index" v-on:click="setProgram(index)" v-bind:class="{selected: activeProgram == index}">
						<div class="input-card-header">
							<img v-bind:src="program.imageUrl" v-bind:alt="program.name" v-bind:title="program.name" />
						</div>
						<div class="input-card-body">
							{{program.description}}
						</div>
					</div>

				</div>

			</section>

			<section class="amounts">

				<h3><span class="section-index">3</span>Select Your Gift Amount</h3>

				<hr />

				<div class="text-center small" v-show="payment.frequency == null">

					You must select a gift frequency before these options will appear.

				</div>

				<div v-if="payment.frequency == 1 && programs.length > 0">

					<div class="input-group" v-bind:class="{'is-invalid': payment.frequency != null && activeGift == null && isSubmitted}">

						<div class="input-card amount" v-for="(program, index) in programs[activeProgram].amounts.single" v-bind:key="program.amount" v-on:click="setGift(program.amount, index)" v-bind:class="{selected: activeGift == index}">
							<div class="input-card-header">
								${{program.amount}}
							</div>
							<div class="input-card-body">
								{{program.label}}
							</div>
						</div>

						<div class="input-card amount" v-bind:class="{selected: activeGift == 4}">
							<div class="input-card-header">
								<input autocomplete="off" inputmode="numeric" type="number" step=".01" placeholder="Amount" class="form-control text-center" v-bind:min="minDonation" v-bind:max="maxDonation" v-model.number="customAmount.single" />
							</div>
							<div class="input-card-body">
								Your Best Gift
							</div>
						</div>

					</div>

				</div>

				<div v-else-if="payment.frequency == 2 && programs.length > 0">

					<div class="input-group" v-bind:class="{'is-invalid': payment.frequency != null && activeGift == null && isSubmitted}">

						<div class="input-card amount" v-for="(program, index) in programs[activeProgram].amounts.monthly" v-bind:key="program.amount" v-on:click="setGift(program.amount, index)" v-bind:class="{selected: activeGift == index}">
							<div class="input-card-header">
								${{program.amount}}
							</div>
							<div class="input-card-body">
								{{program.label}}
							</div>
						</div>

						<div class="input-card amount" v-bind:class="{selected: activeGift == 4}">
							<div class="input-card-header">
								<input autocomplete="off" inputmode="numeric" type="number" step=".01" placeholder="Amount" class="form-control text-center" v-bind:min="minDonation" v-bind:max="maxDonation" v-model.number="customAmount.monthly" />
							</div>
							<div class="input-card-body">
								Your Best Gift
							</div>
						</div>

					</div>

				</div>

				<div class="text-danger text-center small mt-3" v-show="isSubmitted && (payment.frequency != null && activeGift == null)">
					You must select a gift amount.
				</div>

				<div class="text-danger text-center small mt-3" v-show="isSubmitted && (payment.frequency != null && activeGift != null) && (payment.totalAmount < minDonation || payment.totalAmount > maxDonation)">
					Custom gift amounts must be between ${{minDonation}} and ${{maxDonation}}.
				</div>

			</section>

			<section class="billing">

				<h2 class="text-center">Payment Information</h2>

				<hr />

				<DonationPayment v-bind:payment="payment" v-bind:validation="$v" v-bind:is-submitted="isSubmitted" />
					
				<h2 class="text-center">Billing Information</h2>

				<hr />

				<DonationContact v-bind:contact="contact" v-on:update:contact="contact = $event" v-bind:validation="$v" v-bind:hasSMSField="hasSMSField" v-bind:is-submitted="isSubmitted" v-bind:language="language" v-bind:default-country="contact.country" class="mb-5" />				

				
			</section>

			<section class="submit">

				<div class="alert alert-danger text-center" v-if="isSubmitted && !isSuccess">
					<span v-html="message"></span>
				</div>

				<div class="form-row justify-content-center">
					<div class="col-xs-12 col-sm-4">
						<button type="button" class="btn btn-primary btn-lg btn-block" v-on:click="postDonation" v-bind:disabled="isProcessing">
							{{buttonText}}
							<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
						</button>
					</div>

					<p class="text-center mt-3"><small>{{disclaimers[language].project}}</small></p>

				</div>

			</section>

		</form>

	</div>

</template>

<script>
import DonationContact from '@/views/partials/Forms/DonationContact.vue';
import DonationPayment from '@/views/partials/Forms/DonationPayment.vue';

import fieldNames from '@/assets/data/fieldnames.json';
import disclaimers from '@/assets/data/disclaimers.json';
import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';
import programs from '@/assets/data/programs.json';

import { minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';

export default {
	name: 'DonationMain',
	components: {
		DonationContact,
		DonationPayment
	},
	data () {
		return {
			fieldNames: fieldNames,
			disclaimers: disclaimers,
			logos: this.$route.query.version == 2 ? logosTest : logos,
			programs: [],
			language: 'en',
			primaryId: 2,
			secondaryId: 1,
			motivationCode: this.$application.motivationCode,
			emailId: this.$route.query.emid === undefined ? '' : this.$route.query.emid,
			projectCode: null,
			minDonation: 5,
			maxDonation: 25000,
			customAmount: {
				single: null,
				monthly: null
			},
			buttonText: 'Donate',
			activeProgram: 0,
			activeGift: null,
			hasSMSField: true,
			redirectUrl: '/donate/thankyou/',
			contact: {
				firstName: '',
				lastName: '',
				streetAddress: '',
				city: '',
				state: '',
				country: 'US',
				postalCode: '',
				emailAddress: '',
				phoneNumber: '',
				sms: true,
				SMSKeyWord: ''
			},
			payment: {
				totalAmount: 0,
				frequency: null,
				method: 1,
				details: {
					creditCard: {
						fullName: '',
						number: '',
						expireMonth: null,
						expireYear: null,
						cvv: ''
					},
					ach: {
						name: '',
						accountNumber: '',
						routingNumber: '',
						confirmation: false
					},
					paypal: {
						returnUrl: window.location.origin + '/donate/thankyou',
						cancelUrl: window.location.href
					}
				}
			},
			gifts: [],
			isGiftValid: true,
			isProcessing: false,
			isSubmitted: false,
			isSuccess: true,
			message: ''
		}
	},
		validations () {
		
		return {
			contact: {
				firstName: {
					required
				},
				lastName: {
					required
				},
				emailAddress: {
					required
				},
				phoneNumber: {
					required,
					numeric,
					minLength: minLength(10)
				},
				streetAddress: {
					required
				},
				city: {
					required
				},
				state: {
					required
				},
				postalCode: {
					required
				},
				country: {
					required
				}
			},
			
			payment: {
				details: {
					creditCard: {
						fullName: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						number: {
							numeric,
							minLength: minLength(12),
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						expireMonth:{
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						expireYear: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						cvv: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						}
					},
					ach: {

						accountNumber: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						},
						routingNumber: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						},
						confirmation: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						}
					}
				}
			}
		}
	},
	created () {

		

		if(this.hasSMSField){

			this.contact.SMSKeyWord = this.language === 'es' ? 'TierraSanta' : 'Israel';
		}
		

		if (this.$route.query.programs === 'erap') {

			this.secondaryId = 2;
			this.programs.push(programs[4],programs[0], programs[1], programs[2], programs[3]);
		}
		else {

			this.programs.push(programs[0], programs[1], programs[2], programs[3]);
		}
	},
	watch: {
		
		'customAmount.single': function (amount) {

			this.setGift(amount, 4);
		},
		'customAmount.monthly': function (amount) {

			this.setGift(amount, 4);
		},
		'gifts': {
			deep: true,
			handler: function () {

				this.setTotalAmount();
				this.setGiftsValid();
			}
		}
	},
	methods: {

		setFrequency (frequency) {

			this.payment.frequency = frequency;
			this.activeGift = null;
		},

		setProgram (activeProgram) {

			this.activeProgram = activeProgram;
			this.activeGift = null;
			this.gifts = [];
		},

		setGift (amount, activeGift) {

			this.activeGift = activeGift;

			this.gifts = [
				{
					amount: Number(amount),
					projectCode: this.programs[this.activeProgram].projectCode
				}
			]
		},

		setGiftsValid () {

			for (let i = 0; i < this.gifts.length; i++) {

				if (this.gifts[i].amount === '' || this.gifts[i].amount < 0) {

					this.gifts[i].isValid = false;
				}
				else {

					this.gifts[i].isValid = true;
				}
			}

			for (let i = 0; i < this.gifts.length; i++) {

				if (!this.gifts[i].isValid) {

					this.isGiftValid = false;

					break
				}
				else {

					this.isGiftValid = true;
				}
			}
		},

		setTotalAmount () {

			let totalAmount = 0;

			for (let i = 0; i < this.gifts.length; i++) {

				if (this.gifts[i].amount > 0) {

					totalAmount += this.gifts[i].amount;
				}
			}

			this.payment.totalAmount = Number(totalAmount.toFixed(2));
		},

		setAcknowledgment (donation) {

			let acknowledgment = {
				donationKey: donation.donationKey,
				projectCode: '',
				motivationCode: '',
				payment: {
					totalAmount: this.payment.totalAmount,
					frequency: this.payment.frequency
				},
				upsell: {}
			};

			window.sessionStorage.setItem('acknowledgment', JSON.stringify(acknowledgment));
		},

		getPaymentDetails (paymentMethod) {

			if (paymentMethod == 1) {

				return this.payment.details.creditCard;
			}
			else if (paymentMethod == 2) {

				return this.payment.details.ach;
			}
			else if (paymentMethod == 3) {

				return this.payment.details.paypal;
			}
		},

		getGifts () {

			let gifts = [];

			for (let i = 0; i < this.gifts.length; i++) {

				if (this.gifts[i].amount > 0) {

					gifts.push({
						projectCode: this.gifts[i].projectCode,
						amount: this.gifts[i].amount
					});
				}
			}

			return gifts;
		},

		postGtmEvent (donationKey) {

			let items = [];

			for (let i = 0; i < this.gifts.length; i++) {

				items.push({
					item_id: this.primaryId + '.' + this.secondaryId,
					item_name: 'Main Donation Form',
					item_category: this.gifts[i].projectCode,
					item_variant: this.payment.frequency,
					price: this.gifts[i].amount,
					quantity: 1
				});
			}

			let analytics_dataLayer = {
				sessionId: this.$crypto.SHA256(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
				rb_session_id: this.$crypto.SHA1(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
				fullEmailAddress: this.contact.emailAddress,
				motivationCode: this.motivationCode,
				smsOptIn: this.contact.sms,
				paymentMethod: this.payment.method,
				ecommerce: {
					transaction_id: donationKey,
					value: this.payment.totalAmount,
					currency: "USD",
					items: items 
				},
			};

			window.sessionStorage.setItem('analytics_dataLayer', JSON.stringify(analytics_dataLayer));
			
		},

		postDonation () {

			this.isSubmitted = true;
			this.isProcessing = true;

			if (this.$v.$invalid ||
				this.payment.frequency == null || this.gifts.length == 0 || this.payment.totalAmount < this.minDonation || this.payment.totalAmount > this.maxDonation || !this.isGiftValid ||
				(this.payment.method == 2 && this.payment.details.ach.confirmation == false)) {

				this.message = 'The form could not be submitted. Please review the fields above and resubmit.';

				this.isProcessing = false;
				this.isSuccess = false;

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'MainDonation'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							formId: 0,
							primaryId: this.primaryId,
							secondaryId: this.secondaryId,
							motivationCode: this.motivationCode,
							emailId: this.emailId,
							contact: this.contact,
							payment: {
								frequency: this.payment.frequency,
								method: this.payment.method,
								details: this.getPaymentDetails(this.payment.method)
							},
							gifts: this.getGifts(),
							note: ''
						};

						this.$http.post('/forms/donation/', payload, config)
							.then((response) => {

								this.isProcessing = false;

								if (response.data.success) {

									this.isSuccess = true;

									this.setAcknowledgment(response.data);

									this.postGtmEvent(response.data.donationKey);

										if (this.payment.method == 1) {

											window.location.href = this.redirectUrl;
										}
										else if (this.payment.method == 2) {

											window.location.href = this.redirectUrl;
										}
										else if (this.payment.method == 3) {

											window.location.href = response.data.redirectUrl;
										}

								}
								else {

									this.isSuccess = false;
									this.message = 'There was an issue processing your donation. Please contact Donor Support at (800) 486-8844. <br>' + response.data.message;

									this.$newRelicAgent.noticeError(response.data.message, {
										component: 'DonationMain',
										method: 'postDonation',
										type: 'UserException'
									});
								}
							})
							.catch((error) => {

								this.isProcessing = false;
								this.isSuccess = false;
								this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'DonationMain',
									method: 'postDonation',
									type: 'ServerException'
								});
							});
					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.introduction, .frequency, .programs, .amounts, .billing {

	margin-bottom: 2rem;
}

.erap {
	margin-top: -30px;
}

.section-index {

	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: rgba(0, 91, 158);
	color: #fff;
	font-size: 1.25rem;
	line-height: 40px;
	text-align: center;
	vertical-align: text-bottom;
	margin-right: .75rem;
}

.multi-program {

	.total {

		background-color: #eee;
		font-weight: 700;
		margin-top: 1px;
		padding: 10px;
		text-align: center;

		&.is-invalid {

			color: #721c24;
			background-color: #f8d7da;
			border: 1px solid #f5c6cb;
		}

		&.active {

			background-color: rgba(251, 239, 152, 0.5);
		}
	}
	
}
</style>
