<template>

	<div class="donation-upgrade">

		<section class="introduction">

			<div class="row">

				<div class="col-12 text-center">

					<a v-bind:href="logos[donorSatisfaction.language].url">
						<img v-bind:src="logos[donorSatisfaction.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid mb-3" />
					</a>

					<h1 v-html="donorSatisfaction.title"></h1>

				</div>

			</div>

		</section>

		<section class="content">

			<div class="row">

				<div  class="col-sm-12 col-md-12">

					<section class="introduction">
						<p>Thank you for taking just a few moments to answer this short survey. Your answers will help us learn and grow so we can continue to heed God’s call to <em>“Comfort ye, comfort ye my people”</em> (Isaiah 40:1).</p>
					</section>

				</div>

				<div class="col-sm-12 col-md-12">

					<section class="information">

						<form class="donorSatisfactionForm" novalidate v-on:submit.prevent="postDonorSatisfaction" v-bind:class="{'was-validated': isSubmitted}" >

							<div class="form-row">

								<div class="form-group col-md-6">
									<label for="firstName">{{fieldNames[donorSatisfaction.language].firstName}}*</label>
									<input id="firstName" type="text" autocomplete="given-name" maxlength="20" class="form-control" required v-model="contact.firstName">
								</div>

								<div class="form-group col-md-6">
									<label for="lastName">{{fieldNames[donorSatisfaction.language].lastName}}*</label>
									<input id="lastName" type="text" autocomplete="family-name" maxlength="20" class="form-control" required v-model="contact.lastName">
								</div>

							</div>

							<div class="form-row">

								<div class="form-group col-md-6">
									<label for="phoneNumber">{{fieldNames[donorSatisfaction.language].phoneNumber}}*</label>
									<input id="phoneNumber" type="tel" autocomplete="tel" maxlength="13" class="form-control" required v-model="contact.phoneNumber">
								</div>

								<div class="form-group col-md-6">
									<label for="emailAddress">{{fieldNames[donorSatisfaction.language].emailAddress}}*</label>
									<input id="emailAddress" type="email" autocomplete="email" maxlength="50" class="form-control" required v-model="contact.emailAddress">
								</div>

							</div>

							<div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="question1">{{form.question1.label}}</label>
                                    <textarea id="question1" maxlength="250" class="form-control" v-model="form.question1.text"></textarea>
                                </div>
							</div>

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <p><strong>On a scale of 1 to 5, with 1 being Strongly Disagree and 5 being Strongly Agree, please indicate how much you agree with each statement.</strong></p>
                                </div>
                            </div>

							<div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question2">{{form.question2.label}}</label>
                                    <select id="question2" v-model="form.question2.selected" class="custom-select">
										<option v-for="option in form.question2.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>

                            <div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question3">{{form.question3.label}}</label>
                                    <select id="question3" v-model="form.question3.selected" class="custom-select">
										<option v-for="option in form.question3.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>

                            <div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question4">{{form.question4.label}}</label>
                                    <select id="question4" v-model="form.question4.selected" class="custom-select">
										<option v-for="option in form.question4.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>
                            <div class="form-row">
								<div class="form-group col-md-12">
                                    <label for="question5">{{form.question5.label}}</label>
                                    <textarea id="question5" maxlength="250" class="form-control" v-model="form.question5.text"></textarea>
									
                                </div>
							</div>

                            <div class="form-row">
								<div class="form-group col-md-12">
                                    <label for="question5">{{form.question6.label}}</label>
                                    <textarea id="question6" maxlength="250" class="form-control" v-model="form.question6.text"></textarea>
									
                                </div>
							</div>

							<section class="submit">

								<div class="alert alert-danger text-center" v-show="message != ''">{{message}}</div>

								<div class="form-row justify-content-center">
									<div class="col-xs-12 col-md-6">
										<button type="button" class="btn btn-primary btn-lg btn-block" v-on:click="postDonorSatisfaction" v-bind:disabled="isProcessing">
											Submit
											<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
										</button>
									</div>
								</div>

							</section>

						</form>

					</section>
				</div>

			</div>
			
		</section>

	</div>

</template>

<script>
import logos from '@/assets/data/logos.json';
import fieldNames from '@/assets/data/fieldnames.json';

export default {
	name: 'SurveyDonorSatisfaction',
	data () {
		return {
			fieldNames: fieldNames,
			logos: logos,
			motivationCode: this.$application.motivationCode,
			donorSatisfaction: {
				language: 'en',
				redirectUrl: '/donor-satisfaction/thankyou',
				title: 'Your answers will help us learn and grow',
			},
			note: '',
			contact: {
				firstName: '',
				lastName: '',
				emailAddress: '',
				phoneNumber: '',
			},
			form: {
                question1:{
					label:'Why did you choose to support IFCJ?',
					text: ''
				},
                 question2:{
					label:'I am a committed Fellowship Supporter',
					options:[
						{id:'option1', value:'Strongly Disagree - 1'},
						{id:'option2', value:'Disagree - 2'},
						{id:'option3', value:'Neutral - 3'},
                        {id:'option4', value:'Agree - 4 '},
                        {id:'option5', value:'Strongly Agree - 5'},
					],
					selected: null,
				},
				question3:{
					label:'I feel a sense of loyalty to The Fellowship',
					options:[
                        {id:'option1', value:'Strongly Disagree - 1'},
						{id:'option2', value:'Disagree - 2'},
						{id:'option3', value:'Neutral - 3'},
                        {id:'option4', value:'Agree - 4 '},
                        {id:'option5', value:'Strongly Agree - 5'},
					],
					selected: null,
				},
                 question4:{
					label:'The Fellowship is my favorite charitable organization',
					options:[
                        {id:'option1', value:'Strongly Disagree - 1'},
						{id:'option2', value:'Disagree - 2'},
						{id:'option3', value:'Neutral - 3'},
                        {id:'option4', value:'Agree - 4 '},
                        {id:'option5', value:'Strongly Agree - 5'},
					],
					selected: null,
				},
                question5: {
					label:'What can we do to improve your experience as a Fellowship supporter?',
					text: ''
				},
                question6: {
					label:'Is there anything else you’d like to share with The Fellowship today? ',
					text: ''
				} 
			},
			isProcessing: false,
			isSubmitted: false,
			isSuccess: false,
			message: '',
            otherText: '',
            showOtherBox: false
            
		}
	},
	created () {

		
	},
	watch: {
		
	},

	methods: {

		postDonorSatisfaction () {

            this.isSubmitted = true;
			this.isProcessing = true;

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'GenericSurvey'})
				.then((token) => {

					let config = {
						headers: {
							'reCAPTCHA-Token': token
						}
					};

					let	payload = {
						motivationCode: this.motivationCode,
						contact: this.contact,
						note: [
							{question: this.form.question1.label, answer: this.form.question1.text},
							{question: this.form.question2.label, answer: this.form.question2.selected},
							{question: this.form.question3.label, answer: this.form.question3.selected},
							{question: this.form.question4.label, answer: this.form.question4.selected},
							{question: this.form.question5.label, answer: this.form.question5.text},
							{question: this.form.question6.label, answer: this.form.question6.text}
					]
                        

					};

					this.$http.post('/forms/survey/donorSatisfaction', payload, config)
					.then((response) => {
						

						if (response.data.success) {

							this.isSuccess = true;
							this.isProcessing = false;

														
							window.location = this.donorSatisfaction.redirectUrl;
						}
						else {

							this.isSuccess = false;
							this.isProcessing = false;
							this.message = 'Something went wrong, please contact our donor support team at (800) 486-8844 or info@ifcj.org if you have trouble completing your survey.';

							this.$newRelicAgent.noticeError(response.data.message, {
								component: 'SurveyDonorSatisfaction',
								method: 'postDonorSatisfaction',
								type: 'UserException'
							});
						}


					})
					.catch((error) => {

						this.isSuccess = false;
						this.isProcessing = false;
						this.message = error;
					
					});
				});

			});
		}
	}
}
</script>

<style lang="scss" scoped>
.introduction, .content {

	margin-bottom: 2rem;
}
</style>
