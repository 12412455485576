<template>

	<div class="donation-upgrade">

		<section class="introduction">

			<div class="row">

				<div class="col-12 text-center">

					<a v-bind:href="logos[upgrade.language].url">
						<img v-bind:src="logos[upgrade.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid mb-3" />
					</a>

					<h1 v-html="upgrade.title"></h1>

				</div>

			</div>

		</section>

		<section class="content">

			<div class="row">

				<div  class="col-sm-12 col-md-5">

					<section class="introduction">
						<img  src="https://media.ifcj.org/form-media/images/df-14701256.jpg" class="img-fluid mb-3" />
						<p>Almost daily, we hear from vulnerable Jewish communities around the world who need help. The needs are greater than ever. By increasing your monthly gift by just a few dollars each month, more elderly Holocaust survivors and impoverished families can be rescued from starvation and poverty. More persecuted Jews can return home to Israel.<br>
						<br>Please consider increasing your monthly gift so that this critically important work can continue! </p>
					</section>

				</div>

				<div class="col-sm-12 col-md-7">

					<section class="information">

						<form class="upgradeForm" novalidate v-on:submit.prevent="postUpgrade" v-bind:class="{'was-validated': isSubmitted}" >

							<div class="form-row">

									<div class="form-group col-12">

										<div class="table-responsive">

											<table class="table">

												<tbody>
													<tr>
														<th width="45%">Gift Description</th>
														<td>{{upgrade.giftDetails.description}}</td>
													</tr>
													<tr>
														<th>Current Gift Amount</th>
														<td>${{upgrade.giftDetails.amount}}/month</td>
													</tr>
													<tr>
														<th><label for="upgradeAmount">Increase Gift Amount By</label></th>
														<td>
															
															<input inputmode="numeric" id="upgradeAmount" type="number" step="1" placeholder="Amount" class="form-control" v-model.number="form.upgradeAmount" v-bind:min="upgrade.minimumUpgrade" v-bind:max="upgrade.maximumUpgrade" required />
																
															<div class="invalid-feedback" v-show="form.upgradeAmount < upgrade.minimumUpgrade">
																Upgrade amount must be ${{upgrade.minimumUpgrade}} or greater.
															</div>

															<div class="invalid-feedback" v-show="form.upgradeAmount > upgrade.maximumUpgrade">
																Upgrade amount exceeds ${{upgrade.maximumUpgrade}}.
															</div>

														</td>
													</tr>
													<tr>
														<th>New Gift Amount</th>
														<td>${{upgrade.currentAmount}} + <strong class="text-success">${{form.upgradeAmount}}</strong> = <strong>${{upgrade.totalAmount}}</strong>/month</td>
													</tr>
													<tr>
														<th>Account #</th>
														<td>XXXX&ndash;{{upgrade.accountNumber}}</td>
													</tr>
													<tr>
														<th>Bill Date</th>
														<td>{{upgrade.nextGiftDate}}</td>
													</tr>
												</tbody>

											</table>

										</div>

									</div>
							</div>

							<div class="form-row">

								<div class="form-group col-md-6">
									<label for="emailAddress">{{fieldNames[upgrade.language].emailAddress}}*</label>
									<input id="emailAddress" inputmode="email" autocomplete="email" v-model="form.emailAddress" type="email" class="form-control" required>
									<div class="invalid-feedback">
										Please provide a valid email address.
									</div>
								</div>
								<div class="form-group col-md-6">
									<label for="postalCode">{{fieldNames[upgrade.language].postalCode}}*</label>
									<input id="postalCode" autocomplete="postal-code" v-model="form.postalCode" type="text" class="form-control" required>
									<div class="invalid-feedback">
										Please provide a valid zip/postal code.
									</div>
								</div>
								<p class="text-center small">For your protection, please provide the Zip/Postal code on your IFCJ account and the email address you received this request from. If you need help, please contact our Donor Support team at 1-800-486-8844 or through <a href="mailto:info@ifcj.org">info@ifcj.org</a></p>

							</div>

							<section class="submit">

								<div class="alert alert-danger text-center" v-show="message != ''">{{message}}</div>

								<div class="form-row justify-content-center">
									<div class="col-xs-12 col-md-6">
										<button type="button" class="btn btn-primary btn-lg btn-block" v-on:click="postUpgrade" v-bind:disabled="isProcessing">
											Submit
											<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
										</button>
									</div>
								</div>

							</section>

						</form>

					</section>
				</div>

			</div>
			
		</section>

	</div>

</template>

<script>
import logos from '@/assets/data/logos.json';
import fieldNames from '@/assets/data/fieldnames.json';

export default {
	name: 'DonationUpgrade',
	data () {
		return {
			logos: logos,
			fieldNames: fieldNames,
			motivationCode: this.$application.motivationCode,
			upgrade: {
				entityId: this.$route.params.entityId,
				transactionId: this.$route.params.transactionId,
				language: 'en',
				redirectUrl: '/upgrade/thankyou',
				title: 'Increase My Impact',
				currentAmount: 0,
				totalAmount: 0,
				giftDetails: {
					amount: 0,
					description: ''
				}
			},
			form: {
				emailAddress: '',
				postalCode: '',
				upgradeAmount: this.$route.query.amount == undefined ? null : Number(this.$route.query.amount)
			},
			isProcessing: false,
			isSubmitted: false,
			isSuccess: false,
			message: '',
		}
	},
	created () {

		this.getUpgrade(this.upgrade.entityId, this.upgrade.transactionId);
	},
	watch: {
		'form.upgradeAmount': function (amount) {

			this.setTotalAmount(amount);
		}
	},

	methods: {

		setTotalAmount (upgradeAmount) {

			this.upgrade.totalAmount = this.upgrade.currentAmount + upgradeAmount;
		},

		getUpgrade (entityId, transactionId) {

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'GenericDonation'})
				.then((token) => {

					let config = {
						headers: {
							'reCAPTCHA-Token': token
						}
					};

					this.$http.get('/forms/donation/upgrade/' + entityId + '/' + transactionId, config)
					.then((response) => {

						if (response.data.success) {

							this.isSuccess = true;

							this.upgrade = Object.assign(this.upgrade, response.data.upgrade);
							this.upgrade.nextGiftDate = new Date(this.upgrade.nextGiftDate).toLocaleDateString('en-US');

							this.setTotalAmount(this.form.upgradeAmount);
						}
						else {

							this.isSuccess = false;
							this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';

							this.$newRelicAgent.noticeError(response.data.message, {
								component: 'DonationUpgrade',
								method: 'getUpgrade',
								type: 'UserException'
							});
						}

					})
					.catch((error) => {

						this.isSuccess = false;
						this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';

						this.$newRelicAgent.noticeError(error.response.data.message, {
							component: 'DonationUpgrade',
							method: 'getUpgrade',
							type: 'ServerException'
						});
					});
				});
			});
		},

		postUpgrade () {

			this.isSubmitted = true;
			this.isProcessing = true;

			if (this.form.emailAddress == '' ||  this.form.postalCode == '' || (this.form.upgradeAmount < this.upgrade.minimumUpgrade || this.form.upgradeAmount > this.upgrade.maximumUpgrade)) {

				this.isProcessing = false;
				this.message = 'The form could not be submitted. Please review the fields above and resubmit.';

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'MonthlyUpgrade'})
				.then((token) => {

					let config = {
						headers: {
							'reCAPTCHA-Token': token
						}
					};

					let	payload = {
						entityId: this.upgrade.entityId,
						transactionId: this.upgrade.transactionId,
						totalAmount: this.upgrade.totalAmount,
						motivationCode: this.motivationCode,
						emailAddress: this.form.emailAddress,
						postalCode: this.form.postalCode
					};

					this.$http.post('/forms/donation/upgrade', payload, config)
					.then((response) => {

						if (response.data.success) {

							this.isSuccess = true;
							this.isProcessing = false;

							let acknowledgment = {
								totalAmount: this.upgrade.totalAmount,
								nextGiftDate: this.upgrade.nextGiftDate
							};

							window.sessionStorage.setItem('acknowledgment', JSON.stringify(acknowledgment));

							window.dataLayer.push({
								event: 'UpgradeComplete',
								amount: this.upgrade.totalAmount,
								sessionId: this.$crypto.SHA256(this.form.emailAddress).toString(this.$crypto.enc.Hex),
								rb_session_id: this.$crypto.SHA1(this.form.emailAddress).toString(this.$crypto.enc.Hex),
								fullEmailAddress: this.form.emailAddress,
								motivationCode: this.motivationCode
							});
							
							window.location.href = this.upgrade.redirectUrl;
								
						}
						else {

							this.isSuccess = false;
							this.isProcessing = false;
							this.message = 'Your email address or zip code do not match our records and could not be verified. Please contact our donor support team at (800) 486-8844 or info@ifcj.org if you have trouble completing your gift.';

							this.$newRelicAgent.noticeError(response.data.message, {
								component: 'DonationUpgrade',
								method: 'postUpgrade',
								type: 'UserException'
							});
						}


					})
					.catch((error) => {

						this.isSuccess = false;
						this.isProcessing = false;
						this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';

						this.$newRelicAgent.noticeError(error.response.data.message, {
							component: 'DonationUpgrade',
							method: 'postUpgrade',
							type: 'ServerException'
						});
					});
				});

				localStorage.removeItem('upgrade');
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.introduction, .content {

	margin-bottom: 2rem;
}
</style>
